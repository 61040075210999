/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 1.4;
  color: #343434;
  background: #ECECEC;
}

input, textarea {
  font-family: "Muli", sans-serif;
  font-size: 14px;
  color: #343434;
}

.hidden {
  display: none !important;
}

.zsiq_floatmain {
  right: 32px !important;
  bottom: 16px !important;
}

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
.layout_scroll_blocked {
  overflow: hidden;
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.container {
  position: relative;
  flex: 1 1 0;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.container_type_tough {
  flex: none;
}
.container_type_sticky {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.container_type_centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container_type_global-grid {
  width: 290px;
}
.container_type_nav-grid {
  max-width: 525px;
}
.container_type_narrow-grid {
  max-width: 736px;
}
@media (min-width: 375px) {
  .container_type_global-grid {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .container_type_global-grid {
    width: 390px;
  }
}
@media (min-width: 768px) {
  .container_type_nav-grid {
    max-width: none;
  }
  .container_type_global-grid, .container_type_nav-grid {
    width: 680px;
  }
}
@media (min-width: 1024px) {
  .container_type_global-grid, .container_type_nav-grid {
    width: 920px;
  }
}
@media (min-width: 1200px) {
  .container_type_global-grid, .container_type_nav-grid {
    width: 1096px;
  }
}

.container_ratio_universal {
  padding-bottom: 56.25%;
}
.container_ratio_oldskul {
  padding-bottom: 75%;
}
.container_ratio_square {
  padding-bottom: 100%;
}
.container_ratio_portrait {
  padding-bottom: 120%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -32px;
}
.grid__item {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
}
.grid .splitter {
  flex: 1 0 100%;
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_3 {
    width: calc(100% + 32px);
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_3 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
  .grid.grid_cols_3 > .grid__item:last-child {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .grid.grid_cols_3 > .grid__item {
    width: calc(100% / 3 - 32px);
  }
  .grid.grid_cols_3 > .grid__item:last-child {
    margin-right: 32px;
  }
}

.grid_align_center {
  align-items: center;
}

.grid_align_normal {
  align-items: normal;
}

.grid_align_end {
  align-items: end;
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.section {
  position: relative;
  padding: 50px 0;
  background: #fff;
}
.section__heading {
  font-size: 32px;
  font-weight: bold;
  color: #0F2F4C;
  padding-bottom: 20px;
}
.section__heading_align_center {
  text-align: center;
}
.section_spacing_tight .section__heading {
  padding-bottom: 0;
}
.section__subheading {
  font-size: 18px;
}
.section__background-image.image, .section__background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(0, auto, auto, 0);
  overflow: hidden;
}
.section__background-image.image > img {
  position: fixed;
}
.section__background-video > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section__splitter {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 48px 0;
}
.section__splitter_space_large {
  margin: 80px 0;
}
.section__background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 31, 32, 0.5);
}
.section__background-overlay_state_transparent {
  background-color: transparent;
}
.section__heading + .section__content, .section__subheading + .section__content, .section__content + .section__content, .section__content + .section__heading {
  padding-top: 64px;
}
.section_spacing_tight .section__heading + .section__content, .section_spacing_tight .section__subheading + .section__content, .section_spacing_tight .section__content + .section__content {
  padding-top: 24px;
}
.section__content_with_actions {
  margin-bottom: -16px;
}
.section__content_with_actions > .button {
  margin-bottom: 16px;
}
.section__content_with_actions > .button + .button {
  margin-left: 16px;
}
.section__heading + .section__paragraph {
  margin-top: 0;
}
.section_theme_lightest {
  background: #fff;
}
.section_theme_lightest .section__splitter {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}
.section.section_theme_hameleon {
  padding: 0;
  background: transparent;
}
.section__paragraph {
  margin: 16px 0;
  font-size: 14px;
  line-height: 28px;
}
.section__paragraph_with_buttons {
  line-height: 50px;
}
.section__paragraph:first-child {
  margin-top: 0;
}
.section__paragraph:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .section {
    padding: 70px 0;
  }
  .section.section_theme_hameleon {
    padding: 0;
  }
}

.section {
  padding: 70px 0;
}
.section__preheading {
  font-size: 12px;
  text-transform: uppercase;
  color: #81D2C7;
  margin-bottom: 16px;
}
.section__subheading {
  font-size: 16px;
}
.section__divider {
  width: 72px;
  height: 2px;
  background-color: #18639D;
}
.section__divider_align_center {
  margin-left: auto;
  margin-right: auto;
}
.section__divider + .section__subheading, .section__subheading + .section__divider {
  margin-top: 24px;
}
.section__divider + .section__content, .section__content + .section__divider {
  margin-top: 64px;
}
.section__paragraph {
  font-size: 16px;
}

.section__heading_with_line {
  font-size: 20px;
  text-transform: uppercase;
}
.section__heading_with_line:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 12px;
  vertical-align: top;
  width: 40px;
  height: 4px;
  margin-right: 4px;
  background-color: #0F2F4C;
}
.section_theme_darkest .section__heading_with_line:before {
  background-color: #fff;
}
.section__heading + .grid {
  margin-top: 16px;
}
.section__thin-text {
  font-weight: normal;
}

#market-heading {
  font-size: 36px;
}

#special-heading {
  font-size: 36px;
}

#market {
  background-color: #092033;
  padding: 40px 0;
  text-transform: uppercase;
}
#market p {
  color: #777777;
}

#special {
  background-color: #fff;
}
#special .button {
  font-weight: 100;
  border-radius: 0;
}

#market-paragraph {
  font-size: 24px;
  margin-top: 20px;
}

#market-btn {
  font-size: 16px;
  padding: 25px 40px;
  line-height: 1px;
}

#market-btn:hover {
  background: #0F2F4C;
}

#special-btn {
  font-size: 16px;
  padding: 25px 40px;
  line-height: 1px;
  background: #BF2619;
}

#special-btn-two {
  font-size: 16px;
  padding: 25px 40px;
  line-height: 1px;
  background: #0F2F4C;
}

#special-btn:hover {
  background: #0F2F4C;
}

#special-btn-two:hover {
  background: #BF2619;
}

#blue {
  color: #0F2F4C;
}

#market-light {
  font-weight: 100;
}

#market-image {
  max-width: 200px;
}

#specialist-image {
  max-width: 180px;
}

.article-card {
  max-width: 600px;
}

.section_type_cards #news-divider {
  margin-top: 10px;
  height: 2px;
  width: 120px;
  margin-left: -1px;
  background: #0F2F4C;
}
.section_type_cards #letter-divider {
  margin-top: 10px;
  height: 2px;
  width: 180px;
  margin-left: -1px;
  background: #BF2619;
}
.section_type_cards .link_type_accent {
  color: #BF2619 !important;
}
.section_type_cards .link_type_accent:hover {
  color: #0F2F4C !important;
}
.section_type_cards #news-link {
  color: #0F2F4C !important;
  padding-bottom: 10px;
  text-decoration: none !important;
  font-weight: 700;
  margin-top: 20px;
  font-size: 15px;
}
.section_type_cards #news-link:hover {
  color: #BF2619 !important;
}
.section_type_cards .news_type_accent {
  color: #0F2F4C !important;
  margin-left: -2px;
}
.section_type_cards .news_type_accent:hover {
  color: #BF2619 !important;
}
.section_type_cards #letter-link {
  color: #BF2619 !important;
  max-width: 200;
  padding-bottom: 10px;
  text-decoration: none !important;
  font-weight: 700;
  max-width: 200px;
  font-size: 15px;
}
.section_type_cards #letter-link:hover {
  color: #0F2F4C !important;
}
.section_type_cards .letter_type_accent {
  color: #BF2619 !important;
  margin-left: -2px;
}
.section_type_cards .letter_type_accent:hover {
  color: #0F2F4C !important;
}
.section_type_cards .section__preheading {
  margin-top: 60px;
  color: #0F2F4C;
  font-size: 16px;
  font-weight: bold;
}
.section_type_cards .section__divider {
  background: #0F2F4C;
  height: 4px;
  width: 40px;
}
.section_type_cards #read-link {
  color: #BF2619 !important;
  max-width: 100px;
  padding-bottom: 10px;
  text-decoration: none !important;
  font-weight: 700;
  margin-top: 20px;
  font-size: 12px;
}
.section_type_cards #read-link:hover {
  color: #0F2F4C !important;
}
.section_type_cards #read-divider {
  width: 73px;
  background: #BF2619 !important;
  height: 2px;
  margin-top: 2px;
  margin-left: 1px;
}
.section_type_cards .section__subheading {
  max-width: 150px;
}
.section_type_cards #article-category {
  color: #BF2619;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 20px;
  display: inline-block;
  margin-top: 20px;
}
.section_type_cards #article-title {
  color: #0F2F4C;
  font-weight: bold;
  font-size: 12px;
}

#client-section {
  background: rgba(239, 239, 239, 0.67);
}
#client-section .section__heading {
  margin-top: 60px;
  color: #777777;
  font-size: 16px;
}
#client-section .section__divider {
  background: #777777;
  height: 4px;
  width: 40px;
}

@media (min-width: 768px) {
  #special .section__paragraph_with_buttons {
    margin-top: 150px;
  }
}
@media (min-width: 1024px) {
  .section_type_cards #news-link {
    margin-top: 80px;
  }
  .section_type_cards #letter-link {
    margin-left: -50px;
    margin-top: 80px;
  }
  .section_type_cards #news-divider {
    margin-top: 10px;
  }
  .section_type_cards #letter-divider {
    margin-top: 10px;
  }

  #market-image {
    margin-top: 40px;
  }

  #specialist-image {
    margin-top: 10px;
  }

  #client-section {
    padding-top: 70px;
  }
}
@media (min-width: 1200px) {
  #client-section .section__heading {
    display: inline;
    margin-left: 60px;
  }
  #client-section .section__divider {
    margin-top: -13px;
    margin-left: 10px;
  }

  .section_type_cards .section__preheading {
    display: inline;
    margin-left: 60px;
  }
  .section_type_cards .section__divider {
    margin-top: -15px;
    margin-left: 10px;
  }
}
.block + .block {
  margin-top: 24px;
}
.block_with_buttons {
  line-height: 48px;
}

.align_text_center {
  text-align: center;
}

.align_text_right {
  text-align: right;
}

.align_text_left {
  text-align: left;
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.image {
  position: relative;
  width: 100%;
  background-color: #ECECEC;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.image > img:not([src]) {
  display: none;
}
.image_fit_contain > img {
  object-fit: contain;
}
.image_type_transparent {
  background-color: transparent;
}
.image_type_regular, .image_type_fill {
  background-color: transparent;
}
.image_type_regular > img, .image_type_fill > img {
  position: relative;
  height: auto;
  object-fit: contain;
}
.image_type_regular > img {
  width: auto;
  max-width: 100%;
}
.image_type_fill > img {
  width: 100%;
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.link {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: #18639D;
}
.link > svg {
  fill: #18639D;
  vertical-align: middle;
}
.link > svg + span {
  margin-left: 4px;
}
.link:hover {
  color: #BE2418;
}
.link:hover > svg {
  fill: #BE2418;
}
.link_display_inline {
  display: inline;
}
.link_with_icon > svg {
  width: 16px;
}
.link_with_underline {
  text-decoration: underline;
}
.link_type_accent {
  color: #0F2F4C;
}
.link_type_accent:hover {
  color: #BE2418;
}
.link_type_brand {
  line-height: 32px;
  text-align: center;
}
.link_type_brand > svg {
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
}
.link_type_brand:hover > svg, .link_type_brand:active > svg {
  color: #fff;
  fill: #fff;
}
.link_type_underline {
  text-decoration: underline;
}
.link_brand_facebook > svg {
  background-color: #BE2418;
}
.link_brand_facebook:hover > svg {
  background-color: #911b12;
}
.link_brand_twitter > svg {
  background-color: #BE2418;
}
.link_brand_twitter:hover > svg {
  background-color: #911b12;
}
.link_brand_instagram > svg {
  background-color: #BE2418;
}
.link_brand_instagram:hover > svg {
  background-color: #911b12;
}
.link_brand_linkedin > svg {
  background-color: #BE2418;
}
.link_brand_linkedin:hover > svg {
  background-color: #911b12;
}
.link_brand_whatsapp > svg {
  background-color: #BE2418;
}
.link_brand_whatsapp:hover > svg {
  background-color: #911b12;
}
.link_brand_youtube > svg {
  background-color: #BE2418;
}
.link_brand_youtube:hover > svg {
  background-color: #911b12;
}
.link_brand_tiktok > svg {
  background-color: #000;
}
.link_brand_tiktok:hover > svg {
  background-color: #fe2c55;
}
.link_brand_pinterest > svg {
  background-color: #e60023;
}
.link_brand_pinterest:hover > svg {
  background-color: #ad081b;
}
.link_brand_facebook > svg, .link_brand_twitter > svg, .link_brand_instagram > svg, .link_brand_linkedin > svg, .link_brand_whatsapp > svg, .link_brand_youtube > svg, .link_brand_tiktok > svg, .link_brand_pinterest > svg {
  position: relative;
  top: -1px;
}
.link_brand_web > svg {
  background-color: #a2a2a2;
}
.link_brand_web:hover > svg {
  background-color: #adadad;
}
.link_for_mobile {
  display: none;
}
@media only screen and (pointer: coarse) {
  .link_for_desktop {
    display: none;
  }
  .link_for_mobile {
    display: inline-block;
  }
}

.link_with_underline2 {
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid;
  text-transform: uppercase;
}

.link_type_dark {
  color: #BE2418;
}
.link_type_dark:hover {
  color: #fff;
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.menu__item {
  display: block;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
}
.menu__item_type_group > svg {
  width: 8px;
  height: 100%;
  vertical-align: top;
}
.menu__item_type_group > svg:nth-child(2) {
  display: none;
}
.menu__item_type_line-break {
  display: block;
  height: 0;
}
.menu__item.link {
  color: #fff;
}
.menu__item.link > svg {
  fill: #fff;
}
.menu__item.link:hover {
  color: #fff;
}
.menu__item.link:hover > svg {
  fill: #fff;
}
.menu__group-toggle-input {
  display: none;
}
.menu__group-items {
  display: none;
  padding-left: 16px;
  z-index: 1;
}
.menu__group-toggle-input:checked ~ .menu__group-items {
  display: block;
}
.menu__group-toggle-input:checked ~ .menu__item_type_group > svg:nth-child(1) {
  display: none;
}
.menu__group-toggle-input:checked ~ .menu__item_type_group > svg:nth-child(2) {
  display: inline-block;
}
@media (min-width: 1024px) {
  .menu__item {
    display: inline-block;
    white-space: nowrap;
    padding: 0 16px;
    vertical-align: top;
  }
  .menu__item_type_line-break {
    display: block;
  }
  .menu__group {
    display: inline-block;
    position: relative;
  }
  .menu__group-items {
    padding-left: 0;
    position: absolute;
    top: 45px;
    left: 16px;
    text-align: left;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    max-height: 410px;
    overflow-y: auto;
  }
  .menu__group-items > .menu__item.link {
    color: #18639D;
  }
  .menu__group-items > .menu__item.link > svg {
    fill: #18639D;
  }
  .menu__group-items > .menu__item.link:hover {
    color: #fff;
  }
  .menu__group-items > .menu__item.link:hover > svg {
    fill: #fff;
  }
  .menu__group-items > .menu__item {
    display: block;
  }
  .menu__group-items > .menu__item:hover {
    background-color: #BE2418;
  }
}

.menu__item {
  font-size: 16px;
}

.menu__item {
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .menu__item {
    font-size: 11px;
    padding: 0 12px;
  }
}
@media (min-width: 1200px) {
  .menu__item {
    font-size: 12px;
    padding: 0 16px;
  }
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.header {
  background-color: #0F2F4C;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  box-shadow: 0 2px 8px 0 rgba(50, 50, 50, 0.08);
}
.header__container {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.header__topbar {
  position: relative;
  height: 64px;
}
.header__logo {
  display: inline-block;
  position: relative;
  width: 190px;
  height: 100%;
}
.header__logo > svg, .header__logo > img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__toggle {
  position: absolute;
  top: 0;
  right: -12px;
  width: 80px;
  height: 100%;
  cursor: pointer;
}
.header__toggle > svg {
  width: 24px;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__toggle > svg:nth-child(2) {
  display: none;
  width: 20px;
}
.header__toggle-input {
  display: none;
}
.header__menu {
  height: 0;
  overflow: hidden;
  font-size: 0;
  opacity: 0;
}
.header__menu .menu__item_type_icon-inner-link > svg,
.header__menu .menu__item_type_icon-external-link > svg {
  width: 0;
}
.header__menu .menu__item_type_icon-inner-link > svg + span,
.header__menu .menu__item_type_icon-external-link > svg + span {
  margin-left: 0;
}
.header__toggle-input:checked ~ .header__menu {
  height: auto;
  opacity: 1;
  padding-top: 16px;
  transition: padding 0.15s, opacity 0.2s 0.15s;
}
.header__toggle-input:checked ~ .header__topbar > .header__toggle > svg:nth-child(1) {
  display: none;
}
.header__toggle-input:checked ~ .header__topbar > .header__toggle > svg:nth-child(2) {
  display: block;
}
@media (min-width: 768px) {
  .header__container {
    padding: 12px 0;
  }
}
@media (min-width: 1024px) {
  .header {
    overflow: visible;
  }
  .header__toggle {
    display: none;
  }
  .header__container {
    flex-direction: row;
    align-items: center;
  }
  .header__toopbar, .header__menu {
    flex: 1 1 0;
  }
  .header__menu, .header__toggle-input:checked ~ .header__menu {
    height: fit-content;
    transition: none;
    opacity: 1;
    padding-top: 0;
    overflow: visible;
    text-align: right;
  }
  .header__menu .menu__item_type_icon-inner-link, .header__menu .menu__item_type_icon-external-link {
    display: inline-flex;
    font-size: 0;
  }
  .header__menu .menu__item_type_icon-inner-link > svg, .header__menu .menu__item_type_icon-external-link > svg {
    width: 18px;
  }
}

@media (min-width: 768px) {
  .header__container {
    padding: 8px 0;
  }
}
.header__topbar {
  height: 120px;
}
.header__logo {
  width: 180px;
}
.header__banner {
  display: none;
}
@media (min-width: 1200px) {
  .header__banner {
    display: block;
    max-width: 80px;
    position: absolute;
    top: 0;
    right: -16px;
  }
  .header__menu {
    text-align: left;
    padding-left: 12px;
  }
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.footer {
  font-size: 14px;
}
.footer__links + .footer__navigation {
  margin-top: 48px;
}
.footer__navigation-block + .footer__navigation-block {
  margin-top: 16px;
}
.footer__text, .footer__navigation-line {
  line-height: 2em;
}
.footer__copyright {
  text-align: center;
  margin-bottom: 24px;
}
.footer__poweredby {
  text-align: center;
}
.footer__poweredby > .image {
  display: block;
  margin-top: 12px;
  padding-bottom: 45px;
}
.footer__heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.footer .link_type_quick, .footer .link_type_contact > span, .footer .link_type_town {
  text-decoration: underline;
}
.footer .link_type_contact, .footer .link_type_quick {
  display: block;
  margin-top: 16px;
}
.footer .link_type_town {
  font-weight: bold;
}
.footer .link_type_brand:hover > svg, .footer .link_type_brand:active > svg {
  fill: #fff;
}
.footer__partners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
}
.footer__partner {
  display: inline-block;
  margin: 32px;
}
@media (min-width: 768px) {
  .footer__bottom {
    position: relative;
  }
  .footer__copyright {
    height: 45px;
    line-height: 45px;
    margin: 0;
    text-align: left;
  }
  .footer__poweredby {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 45px;
    white-space: nowrap;
  }
  .footer__poweredby > .image {
    width: 80px;
    margin-top: 0;
    margin-left: 12px;
  }
}

.footer__partners {
  justify-content: left;
  padding: 0;
  margin-bottom: 24px;
}
.footer__partner {
  margin: 0;
}
.footer__socials .link_type_brand > svg {
  background-color: transparent;
  padding: 0;
  fill: #fff;
}
.footer__socials .link_type_brand:hover > svg {
  fill: #BE2418;
}

.footer {
  padding: 48px 0 24px;
  font-size: 14px;
  color: #fff;
  background-color: #0F2F4C;
}
.footer__splitter {
  height: 1px;
  background-color: #444956;
  margin: 40px auto;
}
.footer__heading {
  color: #ECECEC;
}
.footer .link {
  color: #ECECEC;
}
.footer .link:hover {
  color: #BE2418;
}
.footer .link_type_contact > svg {
  fill: #ECECEC;
}
.footer .link_type_contact:hover > svg {
  fill: #BE2418;
}

.footer {
  font-size: 16px;
}
.footer__custom-text {
  margin-top: 48px;
}

/* Property Numbers */
/* Service card */
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.cookies-notification {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 90%;
  max-width: 400px;
  max-height: 90%;
  padding: 32px;
  overflow-y: auto;
  z-index: 100000000000000;
  color: #fff;
  background-color: #BE2418;
  font-size: 14px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.cookies-notification__content {
  line-height: 28px;
  margin-bottom: 20px;
}

.cookies-notification__actions {
  display: flex;
}

.cookies-notification__action {
  flex: 1;
  border: 1px solid;
  padding: 12px 0;
  text-align: center;
  cursor: pointer;
}

.cookies-notification__action + .cookies-notification__action {
  margin-left: 16px;
}

.cookies-notification__action_type_close {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.cookies-notification__action_type_close:hover {
  background-color: #fff;
  border-color: #fff;
  color: #BE2418;
}

.cookies-notification__action_type_accept {
  background-color: #fff;
  border-color: #fff;
  color: #BE2418;
}

.cookies-notification__action_type_accept:hover {
  background-color: #BE2418;
  border-color: #BE2418;
  color: #BE2418;
}

.cookies-notification__line + .cookies-notification__line {
  margin-top: 12px;
}

.cookies-notification .link {
  color: #fff;
  text-decoration: underline;
}

.cookies-notification .link:hover {
  color: #BE2418;
}

@media (min-width: 768px) {
  .cookies-notification {
    right: 32px;
  }
}